import { Uploader } from "./uploader";

export class MediaGallery {
  defaults = {
    id: 'pr-content',
    selectModal: '#media-gallery',
    selectUploader: '.uploader',
    selectFileList: '.attachment-list',
    selectListTplElem: '.tpl-attachment-list',
    mediaKitList: '.media-kit-list',
    mediaKitListTplElem: '.tpl-media-kit-list',
    selectActionButton: '.action-button',
    initialImages: [],
    initialAttachments: [],
    initialMediaKit: [],
    onUploadSuccess: null,
    notifier: null,
  };

  constructor(options){
    this.options = Object.assign({}, this.defaults, options);

    this.initDOM();
    this.initUploader();
  };

  initDOM(){
    this.dom = {
      wrapper: document.querySelector(this.options.selectModal),
    };

    this.dom.uploader = this.dom.wrapper.querySelector(this.options.selectUploader);
    this.dom.fileList = this.dom.wrapper.querySelector(this.options.selectFileList);
    this.dom.actionButton = this.dom.wrapper.querySelector(this.options.selectActionButton);
    
    this.dom.mediaKitList = document.querySelector(this.options.mediaKitList);
    
    this.dom.listTpl = document.querySelector(this.options.selectListTplElem);
    this.dom.mediaKitListTpl = document.querySelector(this.options.mediaKitListTplElem);
    
    $(this.dom.actionButton).on('click', this.handleActionButton)
    $(this.dom.wrapper).on('hide.bs.modal', this.handleModalClose)
  };

  handleActionButton = () => {
    this.settlePromise();
  };

  handleModalClose = () => {
    this.settlePromise(false, 'modal closed');
  };

  initUploader(){
    this.uploader = new Uploader({
      target: this.dom.uploader,
      id: this.options.id,
      // meta: {},
      list: {
        source: this.dom.listTpl.innerHTML,
        target: this.dom.fileList,
      },
      mediaKitList: {
        source: this.dom.mediaKitListTpl.innerHTML,
        target: this.dom.mediaKitList,
      },
      notifier: this.options.notifier,
      initialImages: this.options.initialImages,
      initialAttachments: this.options.initialAttachments,
      initialMediaKit: this.options.initialMediaKit,
      onUploadSuccess: this.options.onUploadSuccess,
      isFileUsed: this.options.isFileUsed,
      onFileDeletion: this.options.onFileDeletion,
    });
  };

  open(mode, {selections = []} = {}){
    this.mode = mode;
    this.dom.wrapper.dataset.mode = this.mode;
    
    this.uploader.setAutoAddToMediaKit(this.mode === 'media-kit');
    this.uploader.setSelections(selections/* , mode === 'editor' && {limit: 1} */);
    
    $(this.dom.wrapper).modal('show');
    
    return new Promise((resolve, reject) => {
      this.currentPromise = {resolve, reject};
    });
  };

  settlePromise(fulfilled = true, reason){
    if(!this.currentPromise){
      return;
    }

    if(!fulfilled && this.currentPromise.reject){
      this.currentPromise.reject(reason);
    } else if(fulfilled && this.currentPromise.resolve){
      this.uploader.getSelectedFiles();
      // console.log('this.uploader.getSelectedFiles():', this.uploader.getSelectedFiles())
      this.currentPromise.resolve(this.uploader.getSelectedFiles());
    } else {
      console.warn(`MediaGallery settlePromise not settled when fulfilled is ${fulfilled} and currentPromise is ${this.currentPromise}`);
    }
    this.currentPromise = null;
    $(this.dom.wrapper).modal('hide');
  };

  getFilesForShrine(...args){
    return this.uploader.getFilesForShrine(...args);
  };
};
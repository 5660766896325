import {renderTpl, formatCurrency, uniqueListener, compileTemplates, pickerToRemote, pickerToAPI} from './helpers.js';
import { API } from './api.js';
import Cookies from 'js-cookie';
import { RichEditor } from './rich-editor.js';
import { Uploader } from './uploader.js';
import { Notifier } from './notifier.js';
import { MediaGallery } from './media-gallery.js';

const stripeStyle = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};

const beforeUnloadHandler = function (e) {
  e.preventDefault();
  e.returnValue = '';
};

export class prCreator {
  static instances = [];

  static init(){
    const wrapper = document.querySelector('.pr-creator');
    if(wrapper && !this.instances.some(instance => instance.wrapper === wrapper)){
      this.instances.push(new prCreator(wrapper));
    }
  }

  constructor(wrapper){
    this.dom = {
      wrapper
    };
    this.leavingPrevented = false;

    this.initFields();
    this.initDOM();
    this.initNotifier();
    this.initEditors();
    this.initMediaGallery();
    this.initUploaders();
    this.initTemplates();
    this.initOrder();
    this.initTabs();

    this.stripe = Stripe(window._stripe_public_key);
    this.stripeElements = this.stripe.elements();
  };

  gTagEvent(eventName){
    const tag = 'release_' + eventName.replace(/( |-)/g, '_');
    try{
      // console.log('Setting gtag:', tag);
      gtag('event', tag);
    } catch(e){
      console.warn('Cannot set gtag event:', tag);
    }
  };

  preventLeaving(lock = true){
    if(lock && !this.leavingPrevented){
      this.leavingPrevented = true;
      this.dom.saveDraft.classList.add('active');
      window.addEventListener('beforeunload', beforeUnloadHandler);
    } else if(!lock && this.leavingPrevented){
      this.leavingPrevented = false;
      this.dom.saveDraft.classList.remove('active');
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    }
  };

  initFields(){
    this.fields = {
      title: {checkoutSection: 'content', tab: 'content', errors: []},
      introduction: {checkoutSection: 'content', tab: 'content', errors: []},
      body: {checkoutSection: 'content', tab: 'content', errors: []},
      contact_info: {checkoutSection: 'publish_at', tab: 'media-kit', errors: []},
      save_contact_info_in_user: {checkoutSection: 'media-kit', tab: 'media-kit', errors: []},
      contact_info_not_public: {checkoutSection: 'media-kit', tab: 'media-kit', errors: []},
      publish_at: {checkoutSection: 'publish_at', tab: 'publishing', errors: []},
      publish_asap: {checkoutSection: 'publish_at', tab: 'publishing', errors: []},
      skip_approval: {checkoutSection: 'publish_at', tab: 'publishing', errors: []},
      terms_accepted: {tab: 'checkout', errors: []},
      categories: {checkoutSection: 'categories', tab: 'categories', errors: []},
      options: {checkoutSection: 'options', tab: 'options', errors: []},
    };
  };

  initDOM(){
    this.dom.arrowsPrev = Array.from(this.dom.wrapper.querySelectorAll('.creator-prev-tab'));
    this.dom.arrowsNext = Array.from(this.dom.wrapper.querySelectorAll('.creator-next-tab'));
    this.dom.saveDraft = this.dom.wrapper.querySelector('.creator-save-draft');
    this.dom.finalizeOrder = this.dom.wrapper.querySelector('.creator-finalize-order');

    this.dom.orderId = this.dom.wrapper.querySelector('[name="id"]');

    this.dom.contentForm = this.dom.wrapper.querySelector('form.editor-text');

    this.dom.tabs = Array.from(this.dom.wrapper.querySelectorAll('[data-toggle="tab"]')).reduce((result, elem) => ({...result, [elem.id.replace('-tab', '')]: elem}), {});

    this.dom.fields = Object.fromEntries(Object.keys(this.fields).map(name => [name, this.dom.wrapper.querySelector(`[name="${name}"]`)]).filter(([name, entry]) => entry));
    this.dom.errorMsgs = Array.from(this.dom.wrapper.querySelectorAll('.error-msg[for]')).reduce((result, elem) => ({...result, [elem.getAttribute('for')]: elem}), {});

    // this.dom.uploaderMediaKit = this.dom.wrapper.querySelector('.uploader-media-kit');
    this.dom.mediaKitList = this.dom.wrapper.querySelector('.media-kit-list');
    this.dom.mediaKitEditor = this.dom.wrapper.querySelector('.media-kit-editor');

    this.dom.categories = this.dom.wrapper.querySelectorAll('a[data-type="category"]');
    this.dom.options = this.dom.wrapper.querySelectorAll('a[data-type="option"]');

    this.dom.modals = {
      stripe: this.dom.wrapper.querySelector('#modal-stripe'),
    };

    this.dom.stripe = {
      form: document.getElementById('stripe-payment-form'),
      button: document.getElementById('stripe-request-button'),
      buttonWrapper: document.getElementById('stipe-button-payment'),
      card: document.getElementById('stripe-card-element'),
      cardErrors: document.getElementById('stripe-card-errors'),
      success: document.getElementById('stripe-success'),
    };

    uniqueListener(this.dom.contentForm, 'submit', e => e.preventDefault());

    uniqueListener(this.dom.saveDraft, 'click', this.handleSaveClick);
    uniqueListener(this.dom.finalizeOrder, 'click', this.handleFinalizeClick);
    uniqueListener(this.dom.fields.terms_accepted, 'change', this.handleTermsCheckbox);

    Object.values(this.dom.fields).forEach(field => uniqueListener(field, 'change', () => {
      this.preventLeaving();
      try{
        this.fields[field.name].errors.forEach(err => {
          const index = this.order[this.fields[field.name].checkoutSection].errors.indexOf(err);
          if(index > -1){
            this.order[this.fields[field.name].checkoutSection].errors.splice(index, 1);
          }
        });
        this.fields[field.name].errors = [];
      } catch(e){}
      this.refreshErrors();
    }));

    Array.from(this.dom.categories).forEach(card => uniqueListener(card, 'click', this.handleCategoryClick));
    Array.from(this.dom.options).forEach(card => uniqueListener(card, 'click', this.handleOptionClick));

    Array.from(document.querySelectorAll('[data-action="save-and-show-modal"]')).forEach(link => {
      uniqueListener(link, 'click', this.handleSignupClick);
    });
  };

  initNotifier(){
    this.notifier = new Notifier();

    // this.notifier.show('upload-progress', {progress: 25});
    // this.notifier.show('upload-success');
    // this.notifier.show('upload-error');
  };

  initEditors(){
    this.editors = {};
    window.dbg = this.editors;

    this.editors.intro = new RichEditor(this.dom.fields.introduction, {
      bindings: {
        tab: {
          key: 9, 
          handler: () => this.editors.body.editor.focus(),
        },
        enter: {
          key: 'Enter', 
          handler: () => this.editors.body.editor.focus(),
        },
      }
    });
    this.editors.body = new RichEditor(this.dom.fields.body, {
      sideMenu: {
        action: () => this.mediaGallery.open('editor'), // must return a Promise!
        // action: () => this.uploader.uploadImage(), // must return a Promise!
      },
    });

    this.dom.fields.title.focus();
  };

  initMediaGallery(){
    this.mediaGallery = new MediaGallery({
      notifier: this.notifier,
      initialImages: pageData.order.images.map(API.parseFile.fromPageData),
      initialAttachments: pageData.order.attachments.map(API.parseFile.fromPageData),
      initialMediaKit: pageData.order.media_kit,
      onUploadSuccess: () => new Promise((resolve, reject) => {
        this.saveOrder('upload')
          .then(resData => {
            resolve({
              images: JSON.parse(resData.release.images).map(API.parseFile.fromUpdateAPI),
              attachments: JSON.parse(resData.release.attachments).map(API.parseFile.fromUpdateAPI),
              media_kit: JSON.parse(resData.release['media_kit']),
            })
          }).catch(reject);
      }),
      isFileUsed: (url) => this.editors.body.isFileUsed(url),
      onFileDeletion: (url) => this.editors.body.removeFileUses(url),
    });

    this.dom.mediaKitEditor.addEventListener('click', e => {
      e.preventDefault();
      this.mediaGallery.open('media-kit').catch(e => null);
    });
  };

  initUploaders(){
    // const listTplElem = document.querySelector('.tpl-attachment-list');
    const mediaKitListTplElem = document.querySelector('.tpl-media-kit-list');
    
    // this.uploader = new Uploader({
    //   target: this.dom.uploader,
    //   id: 'pr-content',
    //   // meta: {},
    //   list: {
    //     source: listTplElem ? listTplElem.innerHTML : null,
    //     target: this.dom.attachmentList,
    //   },
    //   notifier: this.notifier,
    //   initialImages: pageData.order.images.map(API.parseFile.fromPageData),
    //   initialAttachments: pageData.order.attachments.map(API.parseFile.fromPageData),
    //   onUploadSuccess: () => new Promise((resolve, reject) => {
    //     this.saveOrder('upload')
    //       .then(resData => {
    //         resolve({
    //           images: JSON.parse(resData.release.images).map(API.parseFile.fromUpdateAPI),
    //           attachments: JSON.parse(resData.release.attachments).map(API.parseFile.fromUpdateAPI),
    //         })
    //       }).catch(reject);
    //   }),
    // })

    // this.uploaderMediaKit = new Uploader({
    //   target: this.dom.uploaderMediaKit,
    //   id: 'pr-media-kit',
    //   // meta: {},
    //   list: {
    //     source: mediaKitListTplElem ? mediaKitListTplElem.innerHTML : null,
    //     target: this.dom.mediaKitList,
    //   },
    //   notifier: this.notifier,
    //   initialAttachments: pageData.order.media_kits.map(API.parseFile.fromPageData),
    //   onUploadSuccess: () => new Promise((resolve, reject) => {
    //     this.saveOrder('upload')
    //       .then(resData => {
    //         resolve({
    //           attachments: JSON.parse(resData.release.media_kits).map(API.parseFile.fromUpdateAPI),
    //         });
    //       }).catch(reject);
    //   }),
    //   restrictions: {
    //     allowedFileTypes: [
    //       /* zips */ '.zip',
    //     ],
    //   },
    // });
  };

  initTemplates(){
    this.templates = {
      summary: {
        source: this.dom.wrapper.querySelector('.summary .tpl-summary-table').innerHTML,
        target: this.dom.wrapper.querySelector('.summary .summary-table'),
      },
      checkout: {
        source: this.dom.wrapper.querySelector('.checkout .tpl-summary-table').innerHTML,
        target: this.dom.wrapper.querySelector('.checkout .summary-table'),
      },
    };
    compileTemplates(this.templates);
  };

  initOrder(){
    this.order = {
      content: {price: 0},
      categories: {price: 0, active: []},
      options: {price: 0, active: []},
      total: {price: 0},
      publish_at: {date: ''},
      save_contact_info_in_user: {value: false},
      contact_info_not_public: {value: false},
    };

    Object.keys(this.order).forEach(key => this.order[key].errors = []);

    Array.from(this.dom.categories).forEach(elem => {
      if(elem.dataset.active === 'true'){
        elem.classList.add('action-card-selected');
        this.toggleCategory(elem.dataset);
      }
    });

    Array.from(this.dom.options).forEach(elem => {
      if(elem.dataset.active === 'true'){
        elem.classList.add('action-card-selected');
        this.toggleOption(elem.dataset);
      }
    });

    this.refreshSummary();
    this.renderCheckout();
  };

  arrowsEnableDisable(activeTab){
    const disabled = {
      prev: false, 
      next: false,
    };

    if(activeTab.parentElement.matches(':first-of-type')){
      disabled.prev = true;
    } else if(activeTab.parentElement.matches(':last-of-type')){
      disabled.next = true;
    }

    this.dom.arrowsPrev.forEach((elem) => {
      elem.disabled = disabled.prev;
    });
    this.dom.arrowsNext.forEach((elem) => {
      elem.disabled = disabled.next;
    });
  }

  initTabs(){
    const initiallyActive = Object.values(this.dom.tabs).find(elem => elem.classList.contains('active'));
    this.arrowsEnableDisable(initiallyActive);
    this.gTagEvent(initiallyActive.getAttribute('href').replace(/^#(.*?)(-panel)?$/, '$1'));

    $(Object.values(this.dom.tabs)).on('shown.bs.tab', e => {
      const active = e.currentTarget;
      const activeTab = active.getAttribute('href').replace(/^#(.*?)(-panel)?$/, '$1');
      this.gTagEvent(activeTab);

      this.arrowsEnableDisable(active);
      document.body.classList.toggle('summary-hidden', activeTab === 'checkout')

      if(activeTab === 'checkout'){
        this.refreshSummary();
        this.renderCheckout();
      }
    });

    this.arrowsDisabled = false;

    this.dom.arrowsNext.forEach((elem) => {
      elem.addEventListener('click', e => {
        e.preventDefault();
        const tabsArray = Object.values(this.dom.tabs);
        const currentlyActive = tabsArray.findIndex(elem => elem.classList.contains('active'));
        if(!this.arrowsDisabled && currentlyActive < tabsArray.length - 1){
          this.arrowsDisabled = true; setTimeout(() => {this.arrowsDisabled = false}, 250);
          $(tabsArray[currentlyActive + 1]).tab('show');
        }
      });
    });

    this.dom.arrowsPrev.forEach((elem) => {
      elem.addEventListener('click', e => {
        e.preventDefault();
        const tabsArray = Object.values(this.dom.tabs);
        const currentlyActive = tabsArray.findIndex(elem => elem.classList.contains('active'));
        if(!this.arrowsDisabled && currentlyActive > 0){
          this.arrowsDisabled = true; setTimeout(() => {this.arrowsDisabled = false}, 250);
          $(tabsArray[currentlyActive - 1]).tab('show');
        }
      });
    });
  };

  handleCategoryClick = (e) => {
    e.preventDefault();
    const elem = e.currentTarget;
    const active = elem.dataset.active === 'true' ? false : true
    elem.dataset.active = active;
    elem.classList.toggle('action-card-selected', active);
    this.toggleCategory(elem.dataset);
    this.preventLeaving();

    this.fields['categories'].errors.forEach(err => {
      const index = this.order[this.fields['categories'].checkoutSection].errors.indexOf(err);
      if(index > -1){
        this.order[this.fields['categories'].checkoutSection].errors.splice(index, 1);
      }
    });
    this.fields['categories'].errors = [];
    this.refreshErrors();
  };

  toggleCategory({id, name}){
    const index = this.order.categories.active.findIndex(cat => cat.id === id);
    if(index > -1){
      this.order.categories.active.splice(index, 1);
    } else {
      this.order.categories.active.push({id, name: name});
    }
    this.refreshSummary();
  };

  handleOptionClick = (e) => {
    e.preventDefault();
    const elem = e.currentTarget;
    const active = elem.dataset.active === 'true' ? false : true
    elem.dataset.active = active;
    elem.classList.toggle('action-card-selected', active);
    this.toggleOption(elem.dataset);
    this.preventLeaving();
  };

  toggleOption({id, name, price}){
    const index = this.order.options.active.findIndex(elem => elem.name === name);
    if(index > -1){
      this.order.options.active.splice(index, 1);
    } else {
      this.order.options.active.push({id: id, name: name, price: parseFloat(price)});
    }
    this.refreshSummary();
  };

  handleTermsCheckbox = (e) => {
    this.dom.finalizeOrder.classList.toggle('disabled', !e.currentTarget.checked);
  };

  handleSaveClick = (e) => {
    e.preventDefault();
    this.saveOrder('save-draft');
  };

  handleSignupClick = (e) => {
    e.preventDefault();
    this.saveOrder('signup');
  };

  handleFinalizeClick = (e) => {
    e.preventDefault();
    if(!this.dom.fields.terms_accepted.checked){
      this.fields.terms_accepted.errors.push(true);
      this.refreshErrors();
    } else {
      this.finalizeOrder();
    }
  };

  refreshSummary(){
    this.order.content.price = pageData.order.basePrice;
    this.order.categories.price = Math.max(0, this.order.categories.active.length - pageData.order.freeCategories) * pageData.order.categoryPrice;
    this.order.options.price = this.order.options.active.reduce((sum, elem) => sum + elem.price, 0);
    this.order.total.price = (pageData.order.basePrice + this.order.categories.price + this.order.options.price) * (1 + (pageData.order.tax || 0));
    this.order.publish_at.date = this.dom.fields.publish_asap.checked ? 'ASAP' : this.dom.fields.publish_at.value && pickerToRemote($(this.dom.fields.publish_at).datetimepicker('getValue'));

    this.renderSummary();
  };

  renderSummary(){
    Object.keys(this.order).forEach(key => {
      if(typeof this.order[key].price === 'number'){
        this.order[key].price = formatCurrency(this.order[key].price);
      }
    });

    renderTpl(this.templates.summary, this.order);
    const tabTriggers = this.templates.summary.target.querySelectorAll('[data-target-tab]');

    for(let trigger of tabTriggers){
      trigger.addEventListener('click', e => {
        $(this.dom.tabs[e.currentTarget.getAttribute('data-target-tab')]).tab('show');
      });   
    }
  };

  renderCheckout(){
    renderTpl(this.templates.checkout, this.order);

    const tabTriggers = this.templates.checkout.target.querySelectorAll('[data-target-tab]');

    for(let trigger of tabTriggers){
      trigger.addEventListener('click', e => {
        $(this.dom.tabs[e.currentTarget.getAttribute('data-target-tab')]).tab('show');
      });
    }
  };

  refreshOrder(){
    this.refreshSummary();

    this.order.save_contact_info_in_user.value = this.dom.fields.save_contact_info_in_user.checked;
    this.order.contact_info_not_public.value = this.dom.fields.contact_info_not_public.checked;

    this.order.data = {
      title: this.dom.fields.title.value,
      introduction: this.dom.fields.introduction.value,
      body: this.dom.fields.body.value,
      contact_info: this.dom.fields.contact_info.value,
      publish_at: this.dom.fields.publish_at.value && pickerToAPI($(this.dom.fields.publish_at).datetimepicker('getValue')),
      publish_asap: this.dom.fields.publish_asap.checked,
      skip_approval: !this.dom.fields.skip_approval.checked,
      contact_info_not_public: this.order.contact_info_not_public.value,
      // total_price: this.order.total.price,
      categories: this.order.categories.active.map(cat => cat.id),
      options: this.order.options.active.map(item => item.id),
      ...(this.mediaGallery && this.mediaGallery.getFilesForShrine()),
      // ...(this.uploaderMediaKit && {media_kits: this.uploaderMediaKit.getFilesForShrine().attachments}),
    };
  };

  updateReleaseUrls(release){
    this.dom.orderId.value = release.id;
    window.currentRelease = {
      id: release.id,
      public_id: release.public_id,
    };
  };

  saveOrder(source){
    return new Promise((resolve, reject) => {
      this.dom.saveDraft.disabled = true;
      this.dom.saveDraft.classList.remove('error');

      if(this.dom.orderId.value === ''){
        source === 'save-draft' && this.notifier.show('saving-progress', {progress: 25});
        API.call({
          endpoint: API.endpoints.release.create,
          data: {release: {title: ''}},
        }).then(res => {
          try{
            Cookies.set('releaseId', res.data.release.public_id, { expires: 30 });
            this.updateReleaseUrls(res.data.release);
            this.saveOrder(source).then(resolve).catch(reject);
            // todo: display notification
            pageData.user.logged_in
              ? history.pushState(null, document.title, `/releases/${res.data.release.id}/edit`)
              : history.pushState(null, document.title, `/releases/${res.data.release.public_id}/edit`);
          } catch(e){
            this.dom.saveDraft.classList.add('error');
            console.warn('API error (create release):', e);
            // todo: display notification
            reject(e);
          };
          this.dom.saveDraft.disabled = false;
        }).catch(e => {
          this.dom.saveDraft.disabled = false;
          this.dom.saveDraft.classList.add('error');
          source === 'save-draft' && this.notifier.show('saving-error');
          console.warn('API connection error (create release):', e);
          // todo: display notification
          reject(e);
        });
      } else {
        this.refreshOrder();
        
        source === 'save-draft' && this.notifier.show('saving-progress', {progress: 50});
        
        API.call({
          endpoint: API.endpoints.release.update,
          params: {id: this.dom.orderId.value},
          data: {
            release: {
              ...this.order.data,
              ...(source === 'upload' && {save_only_files: true}),
              ...(this.order.save_contact_info_in_user.value && {save_contact_info_in_user: this.order.save_contact_info_in_user.value}),
            },
          },
        }).then(res => {
          this.dom.saveDraft.disabled = false;
          source !== 'upload' && this.preventLeaving(false);
          source === 'save-draft' && this.notifier.show('saving-success');
          this.updateReleaseUrls(res.data.release);
          if(
            !pageData.user.logged_in && !this.userSignedUp
            && source !== 'signup' && source !== 'upload'
            && typeof $ !== 'undefined' && $('#authModal-saved').length
          ){
            $('#authModal-saved').modal('show');
            !window.authCallback && (window.authCallback = {});
            window.authCallback.signup = () => {
              this.userSignedUp = true;
              this.finalizeOrder();
            };
          }
          // todo: display notification
          resolve(res.data);
        }).catch(e => {
          this.dom.saveDraft.disabled = false;
          this.dom.saveDraft.classList.add('error');
          source === 'save-draft' && this.notifier.show('saving-error');
          console.warn('API connection error (update release):', e);
          // todo: display notification
          reject(e);
          if(e && e.response && e.response.status === 422 && e.response.data && e.response.data.errors){
            this.setErrors(e.response.data.errors);
          }
        });
      }
    });
  };

  finalizeOrder(){
    this.dom.finalizeOrder.disabled = true;
    this.dom.finalizeOrder.classList.remove('error');

    this.refreshOrder();
    this.saveOrder('finalize').then(() => {
      // todo: validate
      if(!pageData.user.logged_in && !this.userSignedUp){
        this.dom.finalizeOrder.disabled = false;
      } else {
        API.call({
          endpoint: API.endpoints.release.finalize,
          params: {id: this.dom.orderId.value},
          data: {release: this.order.data},
        }).then(res => {
          this.dom.finalizeOrder.disabled = false;
          try{
            this.startPayment(res.data.payment);
          } catch(e){
            console.warn('payment info error:', e);
          }
        }).catch(e => {
          this.dom.finalizeOrder.disabled = false;
          this.dom.finalizeOrder.classList.add('error');
          // console.warn('API connection error (finalize release):', Object.entries(e));
          if(e && e.response && e.response.status === 422 && e.response.data && e.response.data.errors){
            this.setErrors(e.response.data.errors);
            this.notifier.show('validation-errors');
          }
        });
      }
    }).catch(e => {
      this.dom.finalizeOrder.disabled = false;
      this.dom.finalizeOrder.classList.add('error');
      console.warn('API connection error (update release):', e);
    });
  };

  setErrors(errors){
    Object.keys(this.order).forEach(key => this.order[key].errors = []);
    Object.keys(this.fields).forEach(key => this.fields[key].errors = []);

    Object.entries(errors).forEach(([name, msgs]) => {
      if(typeof this.fields[name] !== 'undefined'){
        this.fields[name].errors.push(...msgs);
        if(typeof this.order[this.fields[name].checkoutSection] !== 'undefined'){
          this.order[this.fields[name].checkoutSection].errors.push(...msgs);
        }
      }
    });

    this.refreshErrors();
  };

  refreshErrors(){
    const tabsWithErrors = [];

    Object.entries(this.fields).forEach(([name, fieldConfig]) => {
      const elem = this.dom.fields[name];
      elem && elem.classList.toggle('error', fieldConfig.errors.length);
      fieldConfig.errors.length && tabsWithErrors.push(fieldConfig.tab);
      if(elem && elem.id){
        Array.from(this.dom.wrapper.querySelectorAll(`[for="${elem.id}"]`)).forEach(label => {
          label.classList.toggle('error', fieldConfig.errors.length);
        })
      }
    });

    Object.entries(this.dom.tabs).forEach(([name, elem]) => {
      elem.classList.toggle('error', tabsWithErrors.includes(name));
    });

    Object.entries(this.dom.errorMsgs).forEach(([name, elem]) => {
      elem.innerHTML = this.fields[name].errors.length ? this.fields[name].errors.join(' ') : '';
    });

    this.renderCheckout();
  };

  startPayment(receivedIntent){
    this.startWalletPayment(receivedIntent);
    this.startCardPayment(receivedIntent);
    $(this.dom.modals.stripe).modal('show');
  };

  startWalletPayment(receivedIntent){
    const paymentRequest = this.stripe.paymentRequest({
      country: 'JP',
      currency: 'usd',
      total: {
        label: 'Total',
        amount: receivedIntent.amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    const prButton = this.stripeElements.create('paymentRequestButton', {
      paymentRequest,
      style: {
        paymentRequestButton: {
          // type: 'buy', // One of 'default', 'book', 'buy', or 'donate'
          theme: 'light-outline', // One of 'dark', 'light', or 'light-outline' // Defaults to 'dark'
          height: '50px', // Defaults to '40px'. The width is always '100%'.
        },
      },
    });

    (async () => {
      // Check the availability of the Payment Request API first.
      const result = await paymentRequest.canMakePayment();
      if (result) {
        prButton.mount(this.dom.stripe.button);
        this.dom.stripe.buttonWrapper.style.display = 'block';
      } else {
        this.dom.stripe.buttonWrapper.style.display = 'none';
      }
    })();

    paymentRequest.on('paymentmethod', async (ev) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const {paymentIntent, error: confirmError} = await this.stripe.confirmCardPayment(
        receivedIntent.client_secret,
        {payment_method: ev.paymentMethod.id},
        {handleActions: false}
      );
    
      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11" instead
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const {error} = await this.stripe.confirmCardPayment(clientSecret);
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
            this.handlePaymentError();
          } else {
            // The payment has succeeded.
            this.handlePaymentSuccess();
          }
        } else {
          // The payment has succeeded.
          this.handlePaymentSuccess();
        }
      }
    });
  };
  
  startCardPayment(receivedIntent){
    const card = this.stripeElements.create("card", { style: stripeStyle });
    card.mount(this.dom.stripe.card);

    card.on('change', (event) => {
      this.dom.stripe.cardErrors.textContent = event.error ? event.error.message : '';
    });

    this.dom.stripe.form.addEventListener('submit', (ev) => {
      ev.preventDefault();
      this.stripe.confirmCardPayment(receivedIntent.client_secret, {
        payment_method: {
          card: card,
          // billing_details: {
          //   name: 'John Doe'
          // }
        }
      }).then(result => {
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          this.handlePaymentError(result.error.message);
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.

            this.handlePaymentSuccess();
          } else {
            // this case isn't well documented, but it seems this is an error
            console.warn('Error during payment process:', result);
            this.handlePaymentError();
          }
        }
      });
    });
  };

  handlePaymentSuccess(){
    this.gTagEvent('order_completed');
    this.dom.stripe.form.classList.add('d-none');

    Array.from(this.dom.stripe.success.querySelectorAll('[data-href-pattern]')).forEach(link => {
      link.href = link.dataset.hrefPattern.replace(/\{id\}/g, this.dom.orderId.value);
    })

    this.dom.stripe.success.classList.remove('d-none');

    API.call({
      endpoint: API.endpoints.release.lock,
      params: {id: this.dom.orderId.value},
    }).catch(e => {
      console.warn('API connection error (lock release):', Object.entries(e));
    });
  };

  handlePaymentError(msg){
    this.dom.stripe.cardErrors.textContent = msg || '';
  };
};

import {formatStatus, DTFormat, formatCurrency} from './helpers.js';

export class tables {
  static init(){
    if(typeof pageData !== 'undefined' && pageData.latestReleases){
      $('.table-latest').bootstrapTable({
        columns: latestReleasesColumns,
        data: pageData.latestReleases,
        sortName: 'created_at',
        sortOrder: 'desc'
      })
    }

    if(typeof pageData !== 'undefined' && pageData.allReleases){
      $('.table-all').bootstrapTable({
        columns: latestReleasesColumns,
        data: pageData.allReleases,
        pagination: true,
        sortName: 'created_at',
        sortOrder: 'desc'
      })
    }
  };
};

const titleFormatter = titleValue => `<p class='table-item-title'> ${titleValue} </p>`;

export const statusFormatter = status => {
  if(!status) {
    return '';
  }
  const statusFormatted = formatStatus(status);
  const chipClass =
    (status == 'draft' || status == 'waiting_for_payment') ? 'draft' :
    (status == 'submitted') ? 'submitted' :
    (status == 'content_ready') ? 'contentReady' :
    (status == 'pending_customer_confirmation') ? 'pendingConfirmation' :
    (status == 'ready_to_publish') ? 'readyToPublish' :
    (status == 'published') ? 'published' :
    (status == 'report_ready') ? 'reportReady' :
    (status == 'completed') ? 'completed' :
    (status == 'paid') ? 'success' :
    (status == 'submitted') ? 'danger' :
    'default';
    return `<div class='chip size-medium variant-${chipClass}'><p>${statusFormatted}</p></div>`;
};

const dateFormatter = value => moment(value).tz(pageData.user.timezone).format(DTFormat);

const currencyFormatter = value => formatCurrency(parseFloat(value));

const latestReleasesColumns = [
  {
    field: 'title',
    title: 'Title',
    width: 300,
    sortable: true,
    formatter: titleFormatter,
  },
  {
    field: 'created_at',
    title: 'Created on',
    sortable: true, 
    formatter: dateFormatter,
  },
  {
    field: 'publish_at',
    title: 'Publish(ed) on',
    sortable: true, 
    formatter: dateFormatter,
  },
  {
    field: 'status',
    title: 'Status',
    sortable: true,
    formatter: statusFormatter,
  },
  {
    field: 'total_dollar_price',
    title: 'Price',
    sortable: true, 
    formatter: currencyFormatter,
  },
  {
    field: 'actions',
    title: 'Actions',
    sortable: false,
  },
];

import Handlebars from 'handlebars';

Handlebars.registerHelper('notEmpty', function (value) {
  return !Handlebars.Utils.isEmpty(value);
});

Handlebars.registerHelper('commas', function (arr, propName) {
  return Array.isArray(arr) ? arr.map(item => item[propName]).join(', ') : arr;
});

Handlebars.registerHelper('ifEquals', function(arg1, arg2, options) {
  return arg1 && arg1 == arg2 ? options.fn(this) : options.inverse(this);
});

export const compileTemplates = (templates) => Object.values(templates).forEach(compileTemplate);

export const compileTemplate = (config) => {
  config.template = Handlebars.compile(config.source)
  return config;
};

export const renderTpl = (config, data) => config.target.innerHTML = config.template(data);

export const formatCurrency = number => number.toFixed(2).toLocaleString(pageData.locale || 'en') + ' ' + pageData.currency;

export const formatDate = date => {
  if(typeof date === 'object' && date.constructor.name === 'Date'){
    return date.toLocaleString(pageData.locale || 'en');
  }
  return date;
};

export const formatStatus = status => typeof status === 'string' ? status.split('_').join(' ') : '';

export const uniqueListener = (elem, event, callback) => {
  elem.removeEventListener(event, callback);
  elem.addEventListener(event, callback);
};

export const locationOrigin = () => {
  return window.location.protocol + "//" 
  + window.location.hostname 
  + (window.location.port ? ':' + window.location.port : '');
};

export const DTFormat = 'YYYY.MM.DD HH:mm';
export const DTFormatForPicker = 'Y.m.d H:i';

export const pickerToMoment = input => {
  if(moment.isDate(input)){
    const pickerTime = moment(input).format(DTFormat);
    return moment.tz(pickerTime, DTFormat, pageData.user.timezone);
  } else {
    return input;
  }
};
export const momentToAPI = input => {
  if(moment.isMoment(input)){
    return input.toISOString();
  } else {
    return input;
  }
};
export const momentToRemote = input => {
  if(moment.isMoment(input)){
    return moment(input).format(DTFormat + ' z');
  } else {
    return input;
  }
};
export const momentToLocal = input => {
  if(moment.isMoment(input)){
    return moment(input).local().format(DTFormat) + ` (${moment.tz.guess()})`;
  } else {
    return input;
  }
};
export const pickerToLocal = input => momentToLocal(pickerToMoment(input));
export const pickerToRemote = input => momentToRemote(pickerToMoment(input));
export const pickerToAPI = input => momentToAPI(pickerToMoment(input));
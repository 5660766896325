import Uppy from '@uppy/core';
import UppyFileInput from '@uppy/file-input';
import UppyS3 from '@uppy/aws-s3';
import '@uppy/core/dist/style.css';
import '@uppy/file-input/dist/style.css';
import emitter from 'namespace-emitter';
import { v4 as uuidv4 } from 'uuid';

import { API } from './api';
import {renderTpl, compileTemplate} from './helpers.js';

export class Uploader {
  defaultConfig = {
    uppy: {
      autoProceed: true,
      allowMultipleUploads: true,
      logger: Uppy.debugLogger,
      restrictions: {
        maxNumberOfFiles: null,
        allowedFileTypes: [
          /* images */ '.jpg', '.png', '.gif', 'image/*',
          /* pdfs */ '.pdf', 'application/pdf',
          /* rich text */ '.doc', '.docx', '.odt', '.rtf', 'application/msword', 'application/rtf', 'application/odt' ,'application/vnd.oasis.opendocument.text', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          /* spreadsheets */ '.xls', '.xlsx', 'application/vnd.ms-excel', 'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          /* presentations */ 'application/vnd.ms-powerpoint', 'application/vnd.oasis.opendocument.presentation', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          /* zips */ '.zip',
        ],
      },
      onBeforeFileAdded: file => {
        if(file.source === 'method:uploadImage' && !file.type.match(/^image\//)){
          return false;
        }
      },
    },
    uppyOneImage: {
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: [
          /* images */ '.jpg', '.png', '.gif', 'image/*',
        ],

      },
    },
    UppyFileInput: {
      pretty: true, 
    },
    uppyS3: {
      companionUrl: API.endpoints.uploads.companionUrl,
    },
  };

  constructor({
    target, // selector or DOM element inside od which the uploader should render
    list = {}, // {source: 'Handlebars template code', target: DOMElement}
    mediaKitList = {}, // {source: 'Handlebars template code', target: DOMElement}
    id = 'uppy', // unique id of uploader widget, id should be constant between reloads (for example "release-creation")
    meta = {}, // meta fields attached to every file
    restrictions = {},
    onUploadSuccess, 
    isFileUsed,
    onFileDeletion,
    initialImages = [], 
    initialAttachments = [], 
    initialMediaKit = [],
    notifier, 
    autoAddToMediaKit = false,
    // selectionLimit = 0,
  }){
    if(!target) return null;

    this.list = {...list};
    this.mediaKitList = {...mediaKitList};
    this.id = id;
    this.meta = meta;
    this.restrictions = restrictions;
    this.onUploadSuccess = onUploadSuccess;
    this.isFileUsed = isFileUsed;
    this.onFileDeletion = onFileDeletion;
    this.events = emitter();
    this.notifier = notifier;
    this.autoAddToMediaKit = autoAddToMediaKit;
    // this.selectionLimit = limit;

    this.files = { // uploaded files
      images: [],
      attachments: [],
    };

    this.inTransit = {
      images: [],
      attachments: [],
    };

    this.initSavedImages(initialImages, initialAttachments, initialMediaKit);
    this.initElements(target);
    this.initLists();
    this.initUppy();
  }

  setAutoAddToMediaKit(autoAddToMediaKit){
    this.autoAddToMediaKit = autoAddToMediaKit;
  };

  initSavedImages(initialImages, initialAttachments, initialMediaKit){
    initialImages.forEach(file => {
      this.files.images.push({...file, selected: false, library: 'images'});
    });
    initialAttachments.forEach(file => {
      this.files.attachments.push({...file, selected: false, library: 'attachments'});
    });
    initialMediaKit.forEach(fileRef => {
      const fileDef = this.files[fileRef.library].find(file => file.id === fileRef.id);
      if(fileDef){
        fileDef.inMediaKit = true;
      }
    });
  };

  insertElement(parent, tag, className){
    const elem = document.createElement(tag);
    Array.isArray(className) ? elem.classList.add(...className) : className && elem.classList.add(className);
    parent.append(elem);
    return elem;
  };

  initElements(target){
    this.dom = {
      target: typeof target === 'string' ? document.querySelector(target) : target,
    };
    
    this.dom.uploadButton = this.insertElement(this.dom.target, 'div', 'uploader-button');
    this.dom.progress = this.insertElement(this.dom.target, 'div', 'uploader-progress');

    this.dom.confirmDeletion = document.querySelector('#media-gallery-deletion-warning');
    this.dom.confirmDeletionMessage = this.dom.confirmDeletion.querySelector('.message');

    this.dom.confirmDeletion.messageData = JSON.parse(this.dom.confirmDeletion.querySelector('.message-data').innerHTML);
    this.dom.confirmDeletion.setContent = (filename, reasons = []) => {
      const data = this.dom.confirmDeletion.messageData;
      let msg = '';
      
      const reasonTexts = reasons.map(key => data.reasons[key]).filter(Boolean);

      if(reasonTexts.length === 1){
        msg = data.template.replace('{{reasons}}', reasonTexts[0]);
      } else if(reasonTexts.length > 1){
        msg = data.template.replace('{{reasons}}', reasonTexts.slice(0, -1).join(data.infix) + data.lastInfix + reasonTexts.slice(-1));
      }

      this.dom.confirmDeletionMessage.innerHTML = msg.replace('{{filename}}', filename);
    };

    if(typeof jQuery !== 'undefined' && typeof jQuery.fn.modal !== 'undefined'){
      this.dom.confirmDeletion.showModal =  () => $(this.dom.confirmDeletion).modal('show');
      this.dom.confirmDeletion.hideModal = () => $(this.dom.confirmDeletion).modal('hide');

      $(this.dom.confirmDeletion).on('hide.bs.modal', e => {
        if(typeof this.dom.confirmDeletion.action === 'function'){
          this.dom.confirmDeletion.action(false);
        }
      });
    } else {
      this.dom.confirmDeletion.showModal = () => this.dom.confirmDeletion.classList.add('show');
      this.dom.confirmDeletion.hideModal = () => {
        this.dom.confirmDeletion.classList.remove('show')
        if(typeof this.dom.confirmDeletion.action === 'function'){
          this.dom.confirmDeletion.action(false);
        }
      };
      Array.from(this.dom.confirmDeletion.querySelectorAll('[data-dismiss="modal"')).forEach(elem => {
        elem.addEventListener('click', () => this.dom.confirmDeletion.hideModal());
      })
    }

    this.dom.confirmDeletion.querySelector('.action-button').addEventListener('click', e => {
      e.preventDefault();
      if(typeof this.dom.confirmDeletion.action === 'function'){
        this.dom.confirmDeletion.action(true);
        this.dom.confirmDeletion.hideModal();
      }
    });

  };

  initUppy(){
    this.uppy = Uppy({
      ...this.defaultConfig.uppy,
      restrictions: {
        ...this.defaultConfig.uppy.restrictions,
        ...this.restrictions,
      },
      id: this.id,
      meta: this.meta,
    }).use(UppyFileInput, {
      ...this.defaultConfig.UppyFileInput,
      target: this.dom.uploadButton,
      locale: {
        strings: {
          chooseFiles: this.dom.target.dataset.label || 'Upload',
        },
      },
    }).use(UppyS3, {
      ...this.defaultConfig.uppyS3,
    });


    this.uppy.on('file-added', () => {
      this.updateProgress(this.uppy.getState().totalProgress * 0.8 + 10);
    });

    this.uppy.on('upload-progress', () => {
      this.updateProgress(this.uppy.getState().totalProgress * 0.8 + 10);
    });

    this.uppy.on('complete', result => {
      // console.log('result:', result)
      if (result.successful.length) {
        const connectionId = uuidv4();
        result.successful.forEach((file) => this.handleBatchUploadEachFile(file, connectionId));
        this.handleBatchUploadSuccess(100, connectionId);
      } else {
        let limitExceeded = false;
        try{
          if(result.failed[0].error === 'Your proposed upload exceeds the maximum allowed size'){
            limitExceeded = true;
          }
        } catch(e){}
        console.warn('upload failed', result.failed);
        this.updateProgress(-1, limitExceeded);
      }
    });
  };

  updateProgress(percentage, limitExceeded){
    if(this.notifier){
      if(percentage >= 100){
        this.notifier.show('upload-success');
      } else if(percentage > -1){
        this.notifier.show('upload-progress', {progress: percentage});
      } else if(limitExceeded) {
        this.notifier.show('upload-errorLimit');
      } else {
        this.notifier.show('upload-error');
      }
    } else {
      this.dom.progress.innerHTML = percentage > -1 ? parseInt(percentage) + '%' : '';
    }
  };

  handleBatchUploadEachFile = (file, connectionId) => {
    const data = {
      ...API.parseFile.fromUppy(file), 
      connectionId,
      selected: true, 
      inMediaKit: this.autoAddToMediaKit,
    };
    
    if(/^image\//.test(file.type)){
      this.inTransit.images.push(data);
    } else {
      this.inTransit.attachments.push(data);
    }
  };

  handleBatchUploadSuccess(setProgress = 100, connectionId){
    if(typeof this.onUploadSuccess === 'function'){
      this.onUploadSuccess().then((res) => {
        this.updateFiles(connectionId, res, setProgress);
      }).catch((err) => {
        console.warn('error handling upload (handleBatchUploadSuccess):', err);
        let limitExceeded = false;
        try{ limitExceeded = limitExceeded || err.response.data.errors.images.includes('Images is invalid')}catch(e){}
        try{ limitExceeded = limitExceeded || err.response.data.errors.attachments.includes('Attachments is invalid')}catch(e){}
        this.updateFiles(connectionId, false, -1, limitExceeded);
      });
    } else {
      this.updateFiles(connectionId, false, setProgress);
    }
  };

  parseUpdatedFile = (file, library) => {
    const oldFile = this.inTransit[library].find(oldFile => oldFile.file_id && oldFile.file_id === file.file_id) 
                || this.files[library].find(oldFile => oldFile.id === file.id && oldFile.library === library);
    
    if(oldFile){
      return {
        ...(oldFile.file_id && oldFile.uppyId && {uppyId: oldFile.uppyId}),
        selected: !!oldFile.selected, 
        inMediaKit: !!oldFile.inMediaKit,
        ...file, 
        library,
      };
    }
    return {
      selected: false, 
      inMediaKit: false,
      ...file, 
      library,
    };
  };
  
  updateFiles = (connectionId, files, setProgress = 100, limitExceeded = false) => {
    // console.log('this.files[library]:', Object.assign({}, this.files[library]));
    // console.log('files.attachments:', JSON.stringify(files.attachments, null, '  '));
    // console.log('this.files.attachments:', JSON.stringify(this.files.attachments, null, '  '));

    const mk = files['media_kit'] || [];

    if(files){
      this.files = {
        images: [
          ...(!files.images ? [] : files.images.map(file => this.parseUpdatedFile(file, 'images'))),
        ],
        attachments: [
          ...(!files.attachments ? [] : files.attachments.map(file => this.parseUpdatedFile(file, 'attachments'))),
        ],
      };

      
      this.events.emit('post-processed:updated-files');
    } else {
      this.events.emit('post-processed:no-files');
    }

    if(connectionId){
      this.inTransit.images = this.inTransit.images.filter(file => file.connectionId !== connectionId)
      this.inTransit.attachments = this.inTransit.attachments.filter(file => file.connectionId !== connectionId)
    }

    this.renderLists();
    typeof setProgress === 'number' && this.updateProgress(setProgress, limitExceeded);
  };

  initLists(){
    if(this.list.source && this.list.target){
      compileTemplate(this.list);
    }
    if(this.mediaKitList.source && this.mediaKitList.target){
      compileTemplate(this.mediaKitList);
    }
    this.renderLists();
  };

  getFileByUid(uid){
    if(!uid){
      console.warn('error updating file description (no id)');
      return false;
    }

    const output = {};

    const [parts, library, id] = /^([^/]+)\/(.*)$/.exec(uid);

    // for (const library in this.files) {
      const index = this.files[library].findIndex(file => file.id === parseInt(id));
      if(index > -1){
        Object.assign(output, {library, index, file: this.files[library][index]});
        // break;
      }
    // }

    if(typeof output.index === 'undefined'){
      console.warn('error updating file description (file not found)');
      return false;
    }

    return output;
  };

  confirmFileDeletion(fileDef){
    return new Promise((resolve, reject) => {
      const reasons = [];
      typeof this.isFileUsed === 'function' && this.isFileUsed(fileDef.file.url) && reasons.push('editor');
      fileDef.file.inMediaKit && reasons.push('media-kit');

      this.dom.confirmDeletion.setContent(fileDef.file.name, reasons);
      this.dom.confirmDeletion.action = confirmed => {
        this.dom.confirmDeletion.action = null;
        resolve(confirmed);
      };
      this.dom.confirmDeletion.showModal();
    })
  };

  handleFileDescriptionUpdate = e => {
    const fileDef = this.getFileByUid(e.currentTarget.dataset.forUid);

    if(fileDef){
      fileDef.file.description = e.currentTarget.value;
    }
    
    // this.renderList();
    this.handleBatchUploadSuccess(null);
  };

  handleFileDeletion = e => {
    const fileDef = this.getFileByUid(e.currentTarget.dataset.forUid);

    if(fileDef){
      this.confirmFileDeletion(fileDef)
      .then(confirmed => {
        if(confirmed){
          this.onFileDeletion(fileDef.file.url);
          this.files[fileDef.library].splice(fileDef.index, 1);
          fileDef.file.uppyId && this.uppy.removeFile(fileDef.file.uppyId);
          this.handleBatchUploadSuccess(null);
        }
      });
    }
  };

  handleFileSelection = e => {
    const fileDef = this.getFileByUid(e.currentTarget.dataset.forUid);
    // console.log('SELECTING fileDef:', fileDef)
    // console.log('SELECTING fileDef.id, fileDef.uppyId:', fileDef.id, fileDef.uppyId)
    if(fileDef){
      fileDef.file.selected = e.currentTarget.checked;
    }
  };
  
  handleMediaKitSelection = e => {
    const fileDef = this.getFileByUid(e.currentTarget.dataset.forUid);
    if(fileDef){
      fileDef.file.inMediaKit = e.currentTarget.checked;
    }

    this.handleBatchUploadSuccess(null);
  };

  renderLists(){
    if(this.list.template){
      renderTpl(this.list, this.files);

      Array.from(this.list.target.querySelectorAll('.file-description')).forEach(elem => {
        elem.addEventListener('change', this.handleFileDescriptionUpdate);
      });
      Array.from(this.list.target.querySelectorAll('.file-remove')).forEach(elem => {
        elem.addEventListener('click', this.handleFileDeletion);
      });
      Array.from(this.list.target.querySelectorAll('.file-select')).forEach(elem => {
        elem.addEventListener('change', this.handleFileSelection);
      });

      Array.from(this.list.target.querySelectorAll('.media-kit-select')).forEach(elem => {
        elem.addEventListener('change', this.handleMediaKitSelection);
      });
    }
    
    if(this.mediaKitList.template){
      renderTpl(this.mediaKitList, {
        images: this.files.images.filter(file => file.inMediaKit),
        attachments: this.files.attachments.filter(file => file.inMediaKit),
      });
    }
  };

  setSelections(newSelections = [], options = {}){
    // if(typeof options.limit !== 'undefined'){
    //   this.selectionLimit = options.limit;
    // }

    this.files.images.forEach(file => {
      file.selected = newSelections.findIndex(sel => sel.id === file.id && sel.library === 'images') > -1;
    });
    this.files.attachments.forEach(file => {
      file.selected = newSelections.findIndex(sel => sel.id === file.id && sel.library === 'attachments') > -1;
    });

    this.renderLists();
  };

  getFiles(){
    return [
      ...this.files.images.map(file => ({...file, library: 'images'})),
      ...this.files.attachments.map(file => ({...file, library: 'attachments'})),
    ];
  };

  getSelectedFiles(){
    return [
      ...this.files.images.filter(file => file.selected).map(file => ({...file, library: 'images'})),
      ...this.files.attachments.filter(file => file.selected).map(file => ({...file, library: 'attachments'})),
    ];
  };

  getFilesForShrine(){
    return {
      images: this.files.images.map(API.parseFile.forUpdateAPI).concat(this.inTransit.images.filter(file => !file.sent).map(API.parseFile.forUpdateAPI)),
      attachments: this.files.attachments.map(API.parseFile.forUpdateAPI).concat(this.inTransit.attachments.filter(file => !file.sent).map(API.parseFile.forUpdateAPI)),
      'media_kit': [
        ...this.files.images.filter(file => file.inMediaKit && file.id).map(file => ({id: file.id, library: file.library})),
        ...this.files.attachments.filter(file => file.inMediaKit && file.id).map(file => ({id: file.id, library: file.library})),
      ],
    };
  };

  uploadImage(){
    return new Promise((resolve, reject) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = this.defaultConfig.uppyOneImage.restrictions.allowedFileTypes.join(',');

      const handleChange = () => {
        // console.log('Change');
        this.uploadImage_callback(resolve, reject, input, Array.from(input.files));
      };

      input.addEventListener('change', handleChange);

      // console.log('gonna click', input === document.activeElement, input)
      input.click();
    });
  };

  uploadImage_callback(resolve, reject, input, files){
    // console.log('uploadImage_callback')
    if(files && Array.isArray(files) && files.length > 0){
      let uploads = files.map(file => ({
        uppyId: this.uppy.addFile({source: 'method:uploadImage', name: file.name, type: file.type, data: file}),
      }));
  
      input.remove();
  
      this.events.once('post-processed', () => {
        // console.log('post-processed', this.files.images)
        uploads = uploads.map(upload => {
          // console.log('upload:', upload)
          const file = this.files.images.find(file => file.uppyId === upload.uppyId);
          // console.log('upload, file:', upload, file, this.files.images)
          if(file && file.url){
            return {
              url: file.url,
              library: 'images',
            };
          } else {
            return false;
          }
        }).filter(Boolean);

        // console.log('uploads:', uploads)
  
        if(uploads.length){
          // console.log('resolve:', typeof resolve, resolve)
          resolve(uploads);
        } else {
          // console.log('reject:', typeof reject, reject)
          reject('no uploads found');
        }
      });  
    } else {
      reject('callback got no files');
    }
    
    input.remove();
  }; 
};
